import React, { useCallback } from 'react';
import { navigate } from 'gatsby'
import * as styles from './burger-menu.module.scss';
import { langs } from '../../../../prismic-configuration';
import { v4 as uuidv4 } from 'uuid';
import { linkResolver } from '../../../utils/linkResolver';


function LanguageSwitcher({ currentLanguage }) {
  const onLanguageChange = useCallback((langCode) => {
    localStorage.setItem("lang", JSON.stringify(langCode));

    navigate(linkResolver({ lang: langCode, type: 'home' }));
  }, []);

  return (
    <ul className={styles.menuContent}>
      {Object.keys(langs).map((region) => (
        <div key={uuidv4()}>
          <li className={styles.region}>
            {region}
          </li>
          {langs[region].map((lang) => (
            <li
              key={uuidv4()}
              className={`${styles.langSwitcher} ${currentLanguage === lang.code ? styles.selectedLang : ''}`}
              onClick={(e) => onLanguageChange(lang.code)}
            >
              <span className={styles.currentLanguage}>
                <span className={styles.currentCountry}>{lang['country']}</span>
                <span className={styles.currentLocale}>{lang['name']}</span>
              </span>
            </li>
          ))}
        </div>
      ))}
    </ul>
  );
}

export default LanguageSwitcher;