import React from 'react';
import { v4 } from 'uuid';
import defaultLogo from "../../../images/Claritycoat AF new.svg";
import { Link } from "gatsby";
import * as styles from './header.module.scss';

const Header = ({ openMenu, logo, links, isHidden = false, headerClass='' }) => {
  return (
    <header className={`${styles.headerWrapper} ${isHidden ? styles.disappear : ''} ${headerClass}`}>
      <Link className={styles.logo} to="/">
        <img src={logo ? logo : defaultLogo} alt="close" />
      </Link>
      <div className={styles.linkBlock}>
        {links && (
          <>
            {links.map(link => (
              <button className={styles.hideMobile} title={link.label} key={v4()}>
                <a className='theme-title' href={link.url}>
                  {link.label}
                </a>
              </button>
            ))}
          </>
        )}
        <button className='theme-title' onClick={openMenu} title="menu">
          Menu
        </button>
      </div>
    </header>
  )
};

export default Header;