import React from 'react';
import { StaticQuery, graphql } from "gatsby"
import * as styles from './footer.module.scss';

const Footer = ({ footerNav = [] }) => {
  const navigation = (footerNav) => (
    <ul className={styles.footer}>
      {footerNav.length > 0
        ? footerNav.map((navItem, i) => (
          <li key={`footer-${i}`}>
            <a href={navItem.footer_link.url}>{navItem.name.text}</a>
          </li>
        ))
        : null
      }
    </ul>
  );

  return (
    <footer className={styles.footerWrapper}>
      {navigation(footerNav)}
    </footer>
  )
};

export default Footer;