import React, {useState, useCallback, useMemo, useEffect} from 'react';
import * as styles from './burger-menu.module.scss';
import closeIcon from '/src/images/icons/close.svg';
import iconGlobe from '/src/images/icons/globe.svg';
import iconBlog from '/src/images/icons/blog.svg';
import iconBack from '/src/images/icons/back.svg';
import { v4 as uuidv4 } from 'uuid';
import LanguageSwitcher from "./language-switcher";
import { defaultLanguage, langs } from '../../../../prismic-configuration';
import BlogListing from "./blog-listing";

const BurgerMenu = ({ isOpen, onClose, currentLanguage = defaultLanguage, topNav = [], blogListing = [] }) => {
  const [isLanguageSwitcherOpen, setIsLanguageSwitcherOpen] = useState(false);
  const [isBlogListingOpen, setIsBlogListingOpen] = useState(false);
  useEffect(() => {
    // open side nav to select language if no language is selected
    const saved = localStorage.getItem("lang");
    const initialValue = JSON.parse(saved);
    setIsLanguageSwitcherOpen(!initialValue);
  }, []);

  const toggleLanguageSwitcher = useCallback(() => {
    setIsLanguageSwitcherOpen(!isLanguageSwitcherOpen);
    setDisplayBackBtn(true);
  }, [isLanguageSwitcherOpen, ]);

  const toggleBlogListing = useCallback(() => {
    setIsBlogListingOpen(!isBlogListingOpen);
    setDisplayBackBtn(true);
  }, [isBlogListingOpen, ]);

  const [displayBackBtn, setDisplayBackBtn] = useState(false);
  const backToMainNav = useCallback(() => {
    setIsLanguageSwitcherOpen(false);
    setIsBlogListingOpen(false);
  }, []);

  const closeNav = useCallback(() => {
    onClose();
    setIsLanguageSwitcherOpen(false);
    setIsBlogListingOpen(false);
  }, [onClose]);

  const selectedLang = useMemo(() => {
    return Object.keys(langs).reduce((acc, region) => {
      const lang = langs[region].find(lang => lang.code === currentLanguage);
      if (lang) {
        return lang;
      }
      return acc;
    }, {});
  }, [currentLanguage]);

  const navigation = (topNav, lang) => (
    <ul className={styles.menuContent}>
      {topNav.length > 0
        ? topNav.map((navItem, i) => (
            <li key={uuidv4()}>
              <a href={navItem.menu_link.url}>{navItem.name.text}</a>
            </li>
        ))
        : null
      }
      <li className={styles.langSwitcher} onClick={e => {e.preventDefault(); toggleBlogListing()}}>
        <span className={styles.iconGlobe}>
          <img src={iconBlog} alt="blog" />
        </span>
        <span className={styles.currentLanguage}>
          {/*  Country */}
          <span className={styles.currentCountry}>View Blogs</span>
        </span>

      </li>
      <li className={styles.langSwitcher} onClick={e => {e.preventDefault(); toggleLanguageSwitcher()}}>
        <span className={styles.iconGlobe}>
          <img src={iconGlobe} alt="globe" />
        </span>
        <span className={styles.currentLanguage}>
          {/*  Country */}
          <span className={styles.currentCountry}>{lang.country}</span>
          <span className={styles.currentLocale}>{lang.name}</span>
        </span>
      </li>
    </ul>
  )

  return (
    <>
      <div className={`${styles.menu} ${isOpen ? styles.open : ''}`}>
        <div className={styles.menuHeader}>
          <button className={styles.closeButton} onClick={closeNav}>
            <img width="24px" height="24px" src={closeIcon} alt="close" />
          </button>
          {(isLanguageSwitcherOpen || isBlogListingOpen) && displayBackBtn &&
            <button className={styles.backButton} onClick={backToMainNav}>
              <img src={iconBack} alt="back"/>
              <span>Back</span>
            </button>
          }
        </div>

        {isBlogListingOpen && <BlogListing blogListing={blogListing} />}
        {isLanguageSwitcherOpen && <LanguageSwitcher currentLanguage={currentLanguage} />}


        {(!isLanguageSwitcherOpen && !isBlogListingOpen) && navigation(topNav, selectedLang)}
      </div>
      <div className={`${styles.overlay} ${isOpen ? styles.openOverlay : ''}`} onClick={onClose} role="dialog" />
    </>
  );
};

export default BurgerMenu;