// extracted by mini-css-extract-plugin
export var menu = "burger-menu-module--menu--Noc-P";
export var open = "burger-menu-module--open--Fkks+";
export var overlay = "burger-menu-module--overlay--wENnj";
export var openOverlay = "burger-menu-module--openOverlay--fTUXH";
export var menuHeader = "burger-menu-module--menuHeader--4TqS7";
export var closeButton = "burger-menu-module--closeButton--sRhzS";
export var backButton = "burger-menu-module--backButton--Wgl2j";
export var menuContent = "burger-menu-module--menuContent--WlyMO";
export var region = "burger-menu-module--region--jnFBV";
export var langSwitcher = "burger-menu-module--langSwitcher--4kO0M";
export var selectedLang = "burger-menu-module--selectedLang--QWRYE";
export var iconGlobe = "burger-menu-module--iconGlobe--hdfJW";
export var currentLanguage = "burger-menu-module--currentLanguage--ipgtv";
export var currentCountry = "burger-menu-module--currentCountry--mfxWp";
export var currentLocale = "burger-menu-module--currentLocale--LZ4dg";