import React from 'react';
import * as styles from './top-arrow.module.scss';

const TopArrow = ({ isHidden, moveToTop }) => {
  return (
    <div
      className={`${styles.arrow} theme-arrow ${isHidden ? styles.disappear : ''}`}
      onClick={moveToTop}
    >
      <div className={styles.a}></div>
    </div>
  )
};

export default TopArrow;