import React from 'react';
import * as styles from './burger-menu.module.scss';
import {Link} from 'gatsby';
import { v4 as uuidv4 } from 'uuid';


function BlogListing({ blogListing }) {
  return (
    <ul className={styles.menuContent}>
      {blogListing.map((navItem) => (
        <li key={uuidv4()}>
          <Link to={navItem.url}>{navItem.data.title.text}</Link>
        </li>
      ))}
    </ul>
  );
}

export default BlogListing;