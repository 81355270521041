import React, { createContext, useState } from "react";

export const ColorThemeContext = createContext();

export const ColorThemeContextProvider = ({ children, colorTheme, setColorTheme }) => {
    const value = {
      colorTheme,
      setColorTheme,
    };

    return (
      <ColorThemeContext.Provider value={value}>
        {children}
      </ColorThemeContext.Provider>
  );
}